// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-project-tsx": () => import("/Users/guillaumejasmin/Development/projects/guillaume-jasmin-portfolio/src/components/Project.tsx" /* webpackChunkName: "component---src-components-project-tsx" */),
  "component---src-pages-404-tsx": () => import("/Users/guillaumejasmin/Development/projects/guillaume-jasmin-portfolio/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/Users/guillaumejasmin/Development/projects/guillaume-jasmin-portfolio/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/guillaumejasmin/Development/projects/guillaume-jasmin-portfolio/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

